import React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Protect = (props) => {
  const { Component } = props;
  const navigate = useNavigate();

  useEffect(() => {
    let login = localStorage.getItem('user');
    if (!login) {
      navigate('/login');
    }
    else if (login){
     navigate('/');
    }
  }, []);

  return (
    <div>
      <Component />
    </div>
  );
};

export default Protect;
