import React from 'react'
import {toast, ToastContainer } from 'react-toastify';
import { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
const Login = () => {
  const navigate=useNavigate();
  const [formData, setFormData] = useState([]);
  const [formErrors, setFormErrors] = useState({ email: '', password: '' });
  const validateForm = () => {
    let errors = { email: '', password: '' };
    let isValid = true;
  
    // validate email field
    if (!formData.email) {
      errors.email = 'Email is required';
      isValid = false;
      toast.error("Email is Empty");
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Email is invalid';
      isValid = false;
    }
  
    // validate password field
    if (!formData.password) {
      errors.password = 'Password is required';
      isValid = false;
      toast.error("Password is Empty");
    }
  
    setFormErrors(errors);
    return isValid;
  };
  
  const handleSubmit = (event) => {
    event.preventDefault();

    if (validateForm()) {
      fetch('https://apidata.foodbaskit.com/login', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept":"application/json"
      },
      body: JSON.stringify(formData),
    })
      .then((res) => {
        if (!res.ok) {
     
          toast.error("login not successfully");
          localStorage.removeItem("user", "logged");
        }
     else if (res.ok) {
     
      localStorage.setItem("user", "logged");
      navigate("/")
     }
        return res.json();
      })
      .then((result) => {
    console.log(result)
      })
      .catch((err) => {
        console.error(err);
        toast.error("login not successfully");
    
      });
    }
  
  };
  

 const handleChange = (event) => {
   setFormData({
     ...formData,
     [event.target.name]: event.target.value,
   });
 };

 useEffect(() => {

  
 }, [])
 
  return (
    <div>
      <div className="container mt-5 mb-5" id="login">
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            <div className="card_wrap">
              <div className="card_body">
              <div className="text-center">
                <h1>
                  Login Admin
                </h1>
              </div>
              <form onSubmit={handleSubmit}>
 <div className="row">
   


     <div className="col-md-12">
     <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">email</label>
    <input type="email" name='email' class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"  onChange={handleChange}/>
    {formErrors.email && <span className="text-danger">{formErrors.email}</span>}
   
  </div>
     </div>


     <div className="col-md-12">
     <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">password</label>
    <input type="number" name='password' class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"  onChange={handleChange}/>
    {formErrors.password && <span className="text-danger">{formErrors.password}</span>}
   
  </div>
     </div>

   

 </div>
  
  
  <button type="submit" class="btn btn_main">Submit</button>
</form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}

export default Login
