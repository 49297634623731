import React from 'react'
import $ from "jquery";
import { NavLink } from 'react-router-dom'
import { AiOutlineDashboard, AiOutlineLogout, AiFillSetting,AiOutlineDoubleLeft } from "react-icons/ai";
import {BiCommentDetail} from "react-icons/bi";
import {BsFillCartCheckFill} from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
const Sidebar = () => {
 const navigate=useNavigate();
function handlesidebar(){

let sidebar_active=$("#sidebar");
sidebar_active.toggleClass("active");
$(".wrapper_content").toggleClass("active");

}

function logout() {

  localStorage.removeItem("user");
  window.location.reload();
if(!localStorage.getItem("user")){
  navigate("/login")
}
 }
  return (
    <div>
          <aside>
        <header>
          <nav className='navbar'>
            <div className="brand_logo">
              <a href="/">
                <img src={require("../Images/logo3.png")} alt="brand" className='img-fluid' />
              </a>
            </div>
            <ul className='navul'>


              <li>
                <NavLink className="nav-item" to="">
                  <div className="box_of_dashbord">
                    <div className="icons">

                      <AiFillSetting />
                    </div>
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-item" to="">
                  <div className="box_of_dashbord" id="logout" onClick={logout}>
                    <div className="icons">

                      <AiOutlineLogout />
                    </div>
                  </div>
                </NavLink>
              </li>



            </ul>
          </nav>
        </header>
        <div className="sidebar" id='sidebar'>
<div className="in_and-out" id='sidebar_active' onClick={handlesidebar}>
  <div className="icon">
    <AiOutlineDoubleLeft/>
  </div>
</div>
          <div className="box_userdetail">
            <div className="avtar">
              <div className="img">
                <img src={require("../Images/logo3.png")}  className='img-fluid rounded-circle' alt="" />
              </div>
             <span className="span_badge">
             user area
             </span>
            </div>

          </div>
          <ul className='nav navbar_pills'>
            <li className='list-item'>
              <NavLink to="/" className="link"> <div className="menu-side">
                <div className="icons">
                  <AiOutlineDashboard />
                </div>
                <div className="name">
                  dashboard
                </div>
              </div></NavLink>


            </li>
            <li className='list-item'>
              <NavLink to="/userdetails" className="link"> <div className="menu-side">
                <div className="icons">
                  <BiCommentDetail />
                </div>
                <div className="name">
                user details
                </div>
              </div></NavLink>


            </li>


            <li className='list-item'>
              <NavLink to="productdetail" className="link"> <div className="menu-side">
                <div className="icons">
                <i class="fa-solid fa-blog"></i>
                </div>
                <div className="name">
            latest blogs
                </div>

              </div></NavLink>


            </li>


            <li className='list-item'>
              <NavLink to="popular" className="link"> <div className="menu-side">
                <div className="icons">
                <i class="fa-solid fa-blog"></i>
                </div>
                <div className="name">
            popular blogs
                </div>

              </div></NavLink>


            </li>


            <li className='list-item'>
              <NavLink to="video" className="link"> <div className="menu-side">
                <div className="icons">
                <i class="fa-solid fa-video"></i>
                </div>
                <div className="name">
            videos blogs
                </div>

              </div></NavLink>


            </li>
            <li className='list-item'>
              <NavLink to="step" className="link"> <div className="menu-side">
                <div className="icons">
                <i class="fa-solid fa-stairs"></i>
                </div>
                <div className="name">
           our next step 
                </div>

              </div></NavLink>


            </li>
            
          </ul>


        </div>

      </aside>
    </div>
  )
}

export default Sidebar;
