import React, { useState, useEffect } from 'react';
import {toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Userdetail = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
 
  useEffect(() => {
    fetch('https://apidata.foodbaskit.com/contact')
      .then((res) => res.json())
      .then((result) => setData(result))
      .catch((err) => console.log(err));
  }, []);

  const deleteRecord = (id) => {
    fetch(`https://apidata.foodbaskit.com/contact/${id}`, {
      method: 'DELETE',
    })
      .then((res) => {
        if (res.ok) {
          // success status (2xx)
          const newData = data.filter((item) => item.id !== id);
          setData(newData);
          toast(`${id} is sucessfuly removed`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
        } else {
          // error status
          throw new Error(`HTTP error ${res.status}`);
        }
      })
      .catch((err) => console.log(err));
  };
  

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(data.length / itemsPerPage);
  const pageNumbers = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <main>
        <div className="container">
          <div className="wrapper_content">
            <div className="heading_main">
              <h1>user contact details</h1>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card_wrap">
                  <div className="card_body">
                    <div className="table-responsive">
                      <table className="table" id="myTable">
                        <thead>
                          <tr>
                            <th scope="col">serial no</th>
                            <th scope="col">name</th>
                            <th scope="col">email</th>
                            <th scope="col">contact</th>
                            <th scope="col">message</th>
                            <th scope="col">actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentItems.map((item,index) => {
                            return (
                              <tr key={item.id}>
                                <th scope="row">{index+1}</th>
                                <td>{item.name}</td>
                                <td>{item.email}</td>
                                <td>{item.contact}</td>
                                <td>{item.message}</td>
                                <td>
                                  <div className="d_flex">
                                    <button
                                      className="btn del"
                                      onClick={() => deleteRecord(item.id)}
                                    >
                                      <i class="fa-solid fa-trash"></i>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className="pagination">
                      <ul>
                        {pageNumbers.map((pageNumber) => (
                          <li
                            key={pageNumber}
                            className={`page-item ${
                              currentPage === pageNumber ? 'active' : ''
                            }`}
                          >
                            <a
                              href="#"
                              onClick={() => paginate(pageNumber)}
                              className="page-link"
                            >
                              {pageNumber}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
</div>
</div>
</div>
  <ToastContainer />
</main>
</div>
);
};

export default Userdetail;
