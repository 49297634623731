import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Sidebar from './Components/Sidebar/Sidebar';
import Dashboard from './Components/Routing/Dashboard';
import Footer from '../src/Components/Footer/Footer';
import Userdetail from './Components/Routing/Userdetail';
import Blogs from './Components/Routing/Blogs';
import Popular from './Components/Routing/Popular';
import Videos from './Components/Routing/Videos';
import Step from './Components/Routing/Step';
import Edit from './Components/Routing/Edit';
import '../src/Components/Css/Style.css';
import Login from './Components/Routing/Login';
import HashLoader from 'react-spinners/HashLoader';
import Protect from './Components/Routing/Protect';

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 3000);
  }, []);

  // Get the current location using the useLocation hook
  const location = useLocation();

  return (
    <div>
   
        {loading ? (
          <div className='loader'>
            <HashLoader color='#e5980f' />
          </div>
        ) : (
          <>
            {/* Conditionally render the Sidebar and Footer components */}
            {location.pathname !== '/login' && <Sidebar />}
            <Routes>
              <Route exact path='/' element={<Protect Component={Dashboard} />} />
              <Route exact path='/userdetails' element={<Userdetail />} />
              <Route exact path='/productdetail' element={<Protect Component={Blogs}/>} />
              <Route exact path='/popular' element={<Protect Component={Popular}/>} />
              <Route exact path='/video' element={<Protect Component={Videos}/>}/>
              <Route exact path='/step' element={<Protect Component={Step}/>} />
              <Route exact path='/edit/:id' element={<Edit />} />
              <Route exact path='/login' element={<Login />} />
            </Routes>
            {location.pathname !== '/login' && <Footer />}
          </>
        )}
    
    </div>
  );
};

export default App;
