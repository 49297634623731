
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import {toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlogForm from './BlogForm';
const Step = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [formData, setFormData] = useState([]);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(data.length / itemsPerPage);
  const pageNumbers = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

     const deleteRecord = (id) => {
       console.log(id);
       fetch(`https://apidata.foodbaskit.com/latest_blogs/${id}`, {
         method: 'DELETE',
       })
         .then((res) => res.json())
         .then(() => {
           // filter out the deleted item from the data array
           toast.success("Sucessfully Deleted")
           const newData = data.filter((item) => item.id !== id);
           setData(newData);
         })
         .catch((err) => console.log(err));
     };


     const handleSubmit = (event) => {
       event.preventDefault();
       fetch('https://apidata.foodbaskit.com/latest_blogs', {
         method: "POST",
         headers: {
           "Content-Type": "application/json",
           "Accept":"application/json"
         },
         body: JSON.stringify(formData),
       })
         .then((res) => {
           // if (!res.ok) {
           //   throw new Error("Network response was not ok");
           //   toast.error("Blog post not edit successfully");
           // }
        
           return res.json();
         })
         .then((result) => {
            // check if result is true
               toast.success("Blog post edited successfully");
               console.log(result);
          })
         .catch((err) => {
           console.error(err);
           toast.error("An error occurred while editing the blog post");
         });
     };
     
  
    const handleChange = (event) => {
      setFormData({
        ...formData,
        [event.target.name]: event.target.value,
      });
    };
    useEffect(() => {
     fetch('https://apidata.foodbaskit.com/latest_blogs')
       .then((res) => res.json())
       .then((result) => {
         const newData = result.filter((item) => item.type === "step");
         setData(newData);
         console.log(newData)
         
       })
       .catch((err) => console.log(err));
   }, []);

  return (
    <div>
               <main>
        <div className="container">
          <div className="wrapper_content">
            <div className="heading_main">
              <h1>Our Next Step blogs</h1>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card_wrap">
                  <div className="card_body">
                  <div className="card_btn">
                    <div className="icon">
                    

                       <button type="button" class="btn btn_add btn_main" data-bs-toggle="modal" data-bs-target="#addlatestblogs">
                       add popular blogs
</button>
                    </div>
                  </div>
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">serial no</th>
                            <th scope="col">name</th>
                            <th scope="col">description</th>
                            <th scope="col">loction</th>
                            <th scope="col">review</th>
                            <th scope="col">category</th>
                            <th scope="col">images</th>
                            <th scope="col">actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentItems.map((item,index) => {
                            return (
                              <tr key={item.id}>
                                <th scope="row">{index+1}</th>
                                <td>{item.name}</td>
                                <td>{item.description}</td>
                                <td>{item.location}</td>
                                <td>{item.review}</td>
                                <td>{item.category}</td>
                                <td><img src={`https://apidata.foodbaskit.com/${item.image}`} alt="" className='w-25 h-25'/></td>
                                <td>
                                  <div className="d_flex">
                                  <NavLink className="btn edit" to={`/edit/${item.id}`} >
  <i className="fa-solid fa-pen-to-square"></i>
</NavLink>


<button className="btn del" onClick={() => deleteRecord(item.id)}>
      <i className="fa-solid fa-trash"></i>
    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className="pagination">
                      <ul>
                        {pageNumbers.map((pageNumber) => (
                          <li
                            key={pageNumber}
                            className={`page-item ${
                              currentPage === pageNumber ? 'active' : ''
                            }`}
                          >
                            <a
                              href="#"
                              onClick={() => paginate(pageNumber)}
                              className="page-link"
                            >
                              {pageNumber}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
<div className="modal_sect">
<div class="modal fade" id="addlatestblogs" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel">Step Blogs</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
 <BlogForm/>
      </div>
    
    </div>
  </div>
</div>
</div>
          {/* the end of container */}
        </div>
        <ToastContainer />
      </main>
    </div>
  )
}

export default Step;
