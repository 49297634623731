import React from 'react'
import PieChart from '../Charts/PieChart.js';
import ApexCharts from '../Charts/ApexChart.js';

const Dashboard = () => {
  return (
    <div>
 <main>
 <div className="container">
  <div className="wrapper_content">
  
      <div className="row">
        <div className="col-md-6 mt-2 mb-2">
<div className="card_wrap">
  <div className="card_body">
  <div className="heading_chart">
  piechart
</div>
<div className="chart">

  <ApexCharts/>
</div>
  </div>
</div>
        </div>

        <div className="col-md-6 mt-2 mb-2">
<div className="card_wrap">
  <div className="card_body">
  <div className="heading_chart">
  piechart
</div>
<div className="chart">
<PieChart/>
</div>
  </div>
</div>
          </div>
      </div>

      <div className="mt-5 mb-5">
      <ApexCharts/>
      </div>
    </div>
  </div>
 </main>
    </div>
  )
}

export default Dashboard
