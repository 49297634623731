import React from 'react'

const Footer = () => {
  return (
    <div>
      <footer className='authorize'>
<div className="fotr">
© 2023 FoodBaskit. All rights reserved.
</div>
</footer>
    </div>
  )
}

export default Footer
