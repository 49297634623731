import { useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const Edit = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    review: "",
    description: "",
    location: "",
    type: "",
    category: "",
    image: null,
    image2: null,
    image3: null,
    image4: null,
  });
  const navigate = useNavigate();

  useEffect(() => {
    fetch("https://apidata.foodbaskit.com/latest_blogs")
      .then((res) => res.json())
      .then((result) => {
        const newData = result.filter((item) => item.id == id);
        setData(newData);
        setFormData(newData[0]);
        console.log(newData);
      })
      .catch((err) => console.log(err));
  }, [id]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleImageChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.files[0],
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const postData = new FormData();
    postData.append("name", formData.name);
    postData.append("review", formData.review);
    postData.append("description", formData.description);
    postData.append("location", formData.location);
    postData.append("type", formData.type);
    postData.append("category", formData.category);
    postData.append("image", formData.image);
    postData.append("image2", formData.image2);
    postData.append("image3", formData.image3);
    postData.append("image4", formData.image4);
    axios
      .put(`https://apidata.foodbaskit.com/latest_blogs/${id}`, postData)
      .then((res) => {
        console.log(res.data.message);
        toast.success("Product Is Updated Successfully");
       
      })
      .catch((err) => {
        console.error(err);
        toast.error("Product Not Updated Successfully");
      });
  };

  return (
    <div>
      <main>
        <div className="container">
          <div className="wrapper_content">
            <div className="row">
              <div className="col-md-12">
                <div className="card_wrap">
                  <div className="card_body">
                    
                    {data.map((item) => {
                      return (
                        <>
                          <form key={item.id} onSubmit={handleSubmit}>
                            <div className="row">
                              <div className="col-md-6">
                                <div class="mb-3">
                                  <label
                                    for="exampleInputEmail1"
                                    class="form-label"
                                  >
                                    name
                                  </label>
                                  <input
          type="text"
          name="name"
          value={formData.name}
          className="form-control"
          id="name"
          aria-describedby="nameHelp"
          onChange={handleChange}
        />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div class="mb-3">
                                  <label
                                    for="exampleInputEmail1"
                                    class="form-label"
                                  >
                                    description
                                  </label>
                                  <input
                                    type="text"
                                    name="description"
                                    class="form-control"
                                    value={formData.description}
                                    id="description"
                                    aria-describedby="emailHelp"
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div class="mb-3">
                                  <label
                                    for="exampleInputEmail1"
                                    class="form-label"
                                  >
                                    review
                                  </label>
                                  <input
                                    type="text"
                                    name="review"
                                    defaultValue={FormData.review}
                                    class="form-control"
                                    id="review"
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div class="mb-3">
                                  <label
                                    for="exampleInputEmail1"
                                    class="form-label"
                                  >
                                    location
                                  </label>
                                  <input
                                    type="text"
                                    name="location"
                                    class="form-control"
                                    value={formData.location}
                                    id="location"
                                    aria-describedby="emailHelp"
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div class="mb-3">
                                  <label
                                    for="exampleInputEmail1"
                                    class="form-label"
                                  >
                                    category
                                  </label>
                                  <input
                                    type="text"
                                    name="category"
                                    class="form-control"
                                    value={formData.category}
                                    id="category"
                                    aria-describedby="emailHelp"
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div class="mb-3">
                                  <label
                                    for="exampleInputEmail1"
                                    class="form-label"
                                  >
                                    media
                                  </label>
                                  <input
          type="file"
          name="image"
          className="form-control"
          id="image"
          accept="image/*"
          onChange={handleImageChange}
        />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div class="mb-3">
                                  <label
                                    for="exampleInputEmail1"
                                    class="form-label"
                                  >
                                    media 2
                                  </label>
                                  <input
          type="file"
          name="image2"
          className="form-control"
          id="image2"
          accept="image/*"
          onChange={handleImageChange}
        />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div class="mb-3">
                                  <label
                                    for="exampleInputEmail1"
                                    class="form-label"
                                  >
                                    media 3
                                  </label>
                                  <input
          type="file"
          name="image3"
          className="form-control"
          id="image3"
          accept="image/*"
          onChange={handleImageChange}
        />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div class="mb-3">
                                  <label
                                    for="exampleInputEmail1"
                                    class="form-label"
                                  >
                                    media4
                                  </label>
                                  <input
          type="file"
          name="image4"
          className="form-control"
          id="image4"
          accept="image/*"
          onChange={handleImageChange}
        />
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div class="mb-3">
                                  <label
                                    for="exampleInputEmail1"
                                    class="form-label"
                                  >
                                    type of blogs
                                  </label>
                                  <input
                                    type="text"
                                    name="type"
                                    value={formData.type}
                                    onChange={handleChange}
                                    
                                    class="form-control"
                                    id="type"
                                    aria-describedby="emailHelp"
                                  />
                                </div>
                              </div>
                            </div>

                            <button type="submit" class="btn btn_main">
                              Submit
                            </button>
                          </form>
                        </>
                      );
                    })}{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </main>
    </div>
  );
};

export default Edit;
