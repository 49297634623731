import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

const BlogForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    review: '',
    description: '',
    location: '',
    type: '',
    category: '',
    image: null,
    image2: null,
    image3: null,
    image4: null
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleImageChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.files[0]
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const postData = new FormData();
    postData.append('name', formData.name);
    postData.append('review', formData.review);
    postData.append('description', formData.description);
    postData.append('location', formData.location);
    postData.append('type', formData.type);
    postData.append('category', formData.category);
    postData.append('image', formData.image);
    postData.append('image2', formData.image2);
    postData.append('image3', formData.image3);
    postData.append('image4', formData.image4);
    axios.post('https://apidata.foodbaskit.com/latest_blogs', postData, {
    
    })
      .then(res => {
        console.log(res.data.message);
        toast.success("Product Is Added Sucessfully")
 
      })
      .catch(err => {
        console.error(err);
        toast.error("Product Not  Added Sucessfully")
   
      });
  };

  return (
    <form onSubmit={handleSubmit} className='p-2'>
    <div className="row">
  <div className="col-md-6">
  <label htmlFor="name">Name</label>
      <input type="text" className='form-control' id="name" name="name" value={formData.name} onChange={handleChange} required />
  </div>
  <div className="col-md-6">
  <label htmlFor="description">Description</label>
      <textarea id="description" className='form-control' name="description" value={formData.description} onChange={handleChange} required></textarea>
  </div>
<div className="col-md-6">
<label htmlFor="review">Review</label>
      <input type="text" className='form-control' id="review" name="review" value={formData.review} onChange={handleChange} required />
</div>
 
 <div className="col-md-6">
 <label htmlFor="location">Location</label>
      <input type="text" className='form-control' id="location" name="location" value={formData.location} onChange={handleChange} required />
 </div>
   <div className="col-md-6">
   <label htmlFor="type">Category</label>
      <input id="type" className='form-control' name="category" value={formData.category} onChange={handleChange} required/>
   </div>
      
     <div className="col-md-6">
     <label htmlFor="category">Type</label>
      <select id="category" className='form-control custom-select' name="type" value={formData.type} onChange={handleChange} required>
        <option value="">Select a Type</option>
        <option value="step">step</option>
        <option value="video">video </option>
        <option value="latest">latest</option>
        <option value="popular">popular</option>
      </select>
     </div>
     <div className="col-md-6">
     <label htmlFor="image">media</label>
      <input type="file" className='form-control' id="image" name="image" onChange={handleImageChange} required />
     </div>
<div className="col-md-6">
<label htmlFor="image2">media 2</label>
  <input type="file" className='form-control' id="image2" name="image2" onChange={handleImageChange} />
</div>
 <div className="col-md-6">
 <label htmlFor="image3">media 3</label>
  <input type="file" className='form-control' id="image3" name="image3" onChange={handleImageChange} />
 </div>
<div className="col-md-6">
<label htmlFor="image4">media 4</label>
  <input type="file" className='form-control' id="image4" name="image4" onChange={handleImageChange} />
</div>
  <button type="submit" className='btn mt-5 btn_main'>Submit</button>
    </div>
</form>


);
};

export default BlogForm;
